<template>
  <div id="merchant-page">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Webhooks')}}</h2>
      <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
        {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
      </vs-alert>
      <span v-else class="font-medium text-primary cursor-pointer">
        <vs-button
          class="px-5 py-3 btn active-btn rounded-lg"
          @click="openWebhookModal('add')"
          :disabled="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant'"
        >
          <div class="flex items-center">
            <span class="text-base">{{$t('Add Webhook')}}</span>
          </div>
        </vs-button>
      </span>
    </div>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="webhooks"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Endpoint')}}</shipblu-th>
        <shipblu-th>{{$t('Subscribed To')}}</shipblu-th>
        <shipblu-th>{{$t('Headers')}}</shipblu-th>
        <shipblu-th class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
         :to="{ query: { webhookID: data[indextr].id, filter: '' }}">
          <shipblu-td @click.stop :data="data[indextr].endpoint" class="order-3 inactive-link">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Endpoint')}}</p>
            <p v-on:click.stop>{{ data[indextr].endpoint }}</p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].subscribed_to">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Subscribed To')}}</p>
            <p v-for="item in data[indextr].subscribed_to" :key="item.index">{{ item }}</p>
          </shipblu-td>

          <shipblu-td @click.stop :data="data[indextr].endpoint" class="order-5 inactive-link">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Headers')}}</p>
            <p v-on:click.stop>{{ data[indextr].headers }}</p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openTestModal(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="ActivityIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Test')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="openWebhookEvents(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View Event Logs')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="openWebhookModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteWebhook(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-webhook :type="type" :data="webhook" :webhookModal="webhookModal" @webhookModal="webhookModal = $event" @loadWebhooks="loadWebhooks"></add-webhook>
    <shipblu-prompt
      class="shipment-modal"
      @close="openTestWebhookModal = false"
      :active.sync="openTestWebhookModal"
      :title="$t('Test Webhook')"
      :buttons-hidden="true">
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input v-validate="'required|'" :label-placeholder="$t('Merchant Order Reference')" name="merchant order referenece" v-model="testWebhookObj.merchant_order_reference" class="mt-10 w-full"/>
          <span class="text-danger text-sm" v-show="errors.has('merchant order referenece')">{{ errors.first('merchant order referenece') }}</span>
        </div>
        <div class="vx-col w-1/2 "> 
          <v-select v-validate="'required'" class="mt-10 w-full" name="status" v-model="testWebhookObj.status" :placeholder="$t('Status')" label="name" :options="subscribe"/>
          <span class="text-danger text-sm" v-show="errors.has('status')">{{ errors.first('status') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="openTestWebhookModal = false" class="font-semibold btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="testWebhook" class="font-semibold btn active-btn ">{{ $t('Test') }}</button>
      </div>
    </shipblu-prompt>
    <test-webhook :test="testResponse" :testWebhookModal="testWebhookModal" @testWebhookModal="testWebhookModal = $event" @loadWebhooks="loadWebhooks"></test-webhook>
  </div>
</template>

<script>
import {sendRequest} from '../../../../http/axios/requestHelper.js'
import i18nData from '../../../../i18n/i18nData.js'
import ShipbluTable from '../../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../../layouts/components/ShipBluTd.vue'
import AddWebhook from './AddWebhook.vue'
import TestWebhook from './TestWebhook.vue'
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import ShipbluPagination from '../../../../layouts/components/ShipbluPagination.vue'
import common from '@/assets/utils/common.js'
export default {
  props: ['merchantStatus'],
  data () {
    return {
      openTestWebhookModal: false,
      testWebhookObj: {},
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      webhooks: [],
      webhookModal: false,
      type: '',
      deleteData: {},
      webhook: {
        headers: ''
      },
      selectedWebhook: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      testResponse: {},
      testWebhookModal: false,
      filters: '',
      tableLoader: false,
      subscribe: [
        'out_for_pickup',
        'picked_up',
        'in_transit',
        'out_for_delivery',
        'delivery_attempted',
        'delivered',
        'return_to_origin',
        'out_for_return',
        'return_postponed',
        'return_attempted',
        'returned',
        'cancelled'
      ],
      merchant: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        params: {
          tab: this.$route.params.tab
        },
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadWebhooks()
    }
  },
  methods: {
    openTestModal (webhook) {
      this.openTestWebhookModal = true
      this.selectedWebhook = webhook
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    openWebhookEvents (webhook) {
      this.$emit('showEvents', true)
      this.$router.push({
        query: {
          filter: '',
          webhookID: webhook.id
        }
      }).catch(() => {})
    },
    testWebhook () {
      const testWebhook = {
        'timestamp': new Date().toISOString(),
        'tracking_number': '123456789',
        'merchant_order_reference': this.testWebhookObj.merchant_order_reference,
        'customer': {
          'full_name': 'Jon Doe',
          'phone': '01234567890'
        },
        'status': this.testWebhookObj.status
      }
      sendRequest(false, false, this, `api/v1/webhooks/${this.selectedWebhook.id}/test/`, 'post', testWebhook, true,
        (response) => {
          this.testWebhookObj = {}
          this.openTestWebhookModal = false
          this.testWebhookModal = true
          this.testResponse = response.data
        }
      )
    },
    loadWebhooks () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/webhooks/${query}`, 'get', null, true,
        (response) => {
          this.webhooks = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = warehouse.name
      })
    },
    openWebhookModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.webhook = {
          headers: ''
        }
      } else {
        this.webhook = data
      }
      this.webhookModal = true
    },
    deleteWebhook (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/webhooks/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Webhook'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadWebhooks()
        }
      )
    }
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.userMerchant = results.userMerchantData
      this.merchant = results.merchantData
    })
    this.filters = this.$route.query.filter ? this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadWebhooks()
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddWebhook,
    TestWebhook,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
